import React, { useState, useEffect, useRef } from 'react';
import styles from './VimeoVideo.module.css';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner.js'

const MOBILE_BREAKPOINT = 930;

const VimeoVideo = ({ videoId = 1034457303, isWorkList = false }) => {
    const videoContainerRef = useRef(null);
    const playerRef = useRef(null);
    const [isMobileDevice, setIsMobileDevice] = useState(false);
    const [isMobileSize, setIsMobileSize] = useState(window.innerWidth <= MOBILE_BREAKPOINT);
    const [isDesktopFS, setIsDesktopFS] = useState(false);
    const [isMuted, setIsMuted] = useState(true);
    const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

    const checkMobile = () => {
        const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        setIsMobileDevice(isMobileDevice);
    };

    const handleResize = () => {
        setIsMobileSize(window.innerWidth <= MOBILE_BREAKPOINT);
    };

    const loadVimeoApi = () => {
        return new Promise((resolve) => {
            if (window.Vimeo) {
                resolve(window.Vimeo);
                return;
            }
            const script = document.createElement('script');
            script.src = 'https://player.vimeo.com/api/player.js';
            script.onload = () => resolve(window.Vimeo);
            document.body.appendChild(script);
            
        });
    };

    const initializePlayer = async () => {
        try {
            const Vimeo = await loadVimeoApi();
            const iframe = videoContainerRef.current?.querySelector('iframe');
            if (iframe) {
                const player = new Vimeo.Player(iframe);
                playerRef.current = player;

                const initialVolume = await player.getVolume();
                setIsMuted(initialVolume === 0);

                player.on('volumechange', event => {
                    setIsMuted(event.volume === 0);
                });

                player.on('fullscreenchange', () => {
                    const isFullscreen = document.fullscreenElement || document.webkitFullscreenElement;
                    if (!isFullscreen && playerRef.current) {
                        console.log('exiting fullScreen')
                        playerRef.current.play().catch((error) => {
                            console.error('Error manually starting playback:', error);
                        });
                    }
                });

                if (!isMobileDevice) {
                    await player.setLoop(true);
                    await player.setVolume(0);
                    if (isChrome) {
                        await player.ready();
                        await new Promise(resolve => setTimeout(resolve, 100));
                    }
                    await player.play();
                }
                
                if (isMobileDevice) {
                    await player.setVolume(0);
                    await player.setLoop(false);
                    await player.play();
                }

                setIsMuted(true);
            }
        } catch (error) {
            console.error('Error initializing Vimeo player:', error);
        }
    };

    const handleFullscreenChange = () => {
        const isFullscreen = document.fullscreenElement || document.webkitFullscreenElement;
        setIsDesktopFS(!!isFullscreen);
        setTimeout(() => {
            if (playerRef.current) {
                if (!isFullscreen) {
                    playerRef.current.setLoop(true);
                }
                playerRef.current.play().catch((error) => {
                    console.error('Error playing video:', error);
                });
            }
        }, 1000);
    };

    useEffect(() => {
        checkMobile();
        initializePlayer();
        window.addEventListener('resize', handleResize);
        document.addEventListener('fullscreenchange', handleFullscreenChange);
        document.addEventListener('webkitfullscreenchange', handleFullscreenChange);

        return () => {
            if (playerRef.current) {
                playerRef.current.destroy();
            }
            window.removeEventListener('resize', handleResize);
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
            document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
        };
    }, []);

    useEffect(() => {
        const handleFirstInteraction = () => {
            if (playerRef.current) {
                playerRef.current.setVolume(1);
            }
            document.removeEventListener('click', handleFirstInteraction);
            document.removeEventListener('keydown', handleFirstInteraction);
            document.removeEventListener('touchstart', handleFirstInteraction);
        };
    
        document.addEventListener('click', handleFirstInteraction);
        document.addEventListener('keydown', handleFirstInteraction);
        document.addEventListener('touchstart', handleFirstInteraction);
    
        return () => {
            document.removeEventListener('click', handleFirstInteraction);
            document.removeEventListener('keydown', handleFirstInteraction);
            document.removeEventListener('touchstart', handleFirstInteraction);
        };
    }, []);

    const toggleFullscreen = async () => {
        if (playerRef.current) {
            try {
                if (document.fullscreenElement) {
                    await document.exitFullscreen();
                } else {
                    await playerRef.current.element.requestFullscreen();
                }
            } catch (error) {
                console.error('Fullscreen toggle error:', error);
            }
        }
    };

    const toggleMute = async (event) => {
        event.preventDefault();
        event.stopPropagation();   
        if (playerRef.current) { 
            try {
                if (isMuted) {
                    await playerRef.current.setVolume(1); 
                } else {                   
                    await playerRef.current.setVolume(0); 
                }
                setIsMuted(prevState => !prevState);
            } catch (error) {
                console.error('Error toggling mute:', error);
            }
        }
    };

    return (
        <div ref={videoContainerRef} className={`${styles.videoWrapper}`}>
            {(!isDesktopFS && !isMobileSize && !isWorkList) && (
            <div className={styles.spinnerWrapper}>
                <LoadingSpinner />
            </div>
            )}
            <iframe
                src={`https://player.vimeo.com/video/${videoId}?dnt=1&responsive=1&title=0&controls=${isDesktopFS || isMobileSize || isWorkList ? '1' : '0'}&background=${isDesktopFS || isMobileSize || isWorkList ? '0' : '1'}&autoplay=1&muted=${isDesktopFS || isMobileSize || isWorkList ? '0' : '1'}&autopause=0&transcript=0&vimeo_logo=0`}
                frameBorder="0"
                allow='autoplay'
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
                className={`${styles.videoContainer} ${isWorkList ? styles.videoWorkList : (!isMobileSize ? styles.videoDesktop : styles.videoMobile)}`}
            />
            {(!isDesktopFS && !isMobileSize && !isWorkList) && (
               <div className={`${styles.controlsContainer} ${isMobileSize ? styles.controlsMobile : styles.controlsDesktop}`}>
                    <div className={styles.muteIcon} onClick={toggleMute}>
                        {!isMuted ? (
                            <i className="fa-solid fa-volume-high"></i>
                        ) : (
                            <i className="fa-solid fa-volume-xmark"></i>
                        )}
                    </div>
                    <svg
                        onClick={toggleFullscreen}
                        width="29"
                        height="29"
                        viewBox="0 0 200 200"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ transform: 'translateY(6px)' }}
                    >
                        <rect width="75" height="25" x="00" y="20" fill="white" />
                        <rect width="75" height="25" x="125" y="20" fill="white" />
                        <rect width="75" height="25" x="00" y="155" fill="white" />
                        <rect width="75" height="25" x="125" y="155" fill="white" />
                        <rect width="25" height="65" x="175" y="20" fill="white" />
                        <rect width="25" height="65" x="00" y="20" fill="white" />
                        <rect width="25" height="65" x="175" y="115" fill="white" />
                        <rect  width="25" height="65" x="00" y="115" fill="white" />
                    </svg>
                </div>
            )}
        </div>
    );
};

export default VimeoVideo;