import Player from '@vimeo/player';
import React, { useState, useRef, useEffect } from 'react';
import styles from './VimeoBackground.module.css';

const VimeoBackgroundPlayer = ({ videoId = 1022553264 }) => {
  const playerContainerRef = useRef(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [playerReady, setPlayerReady] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);

  const safeCall = async (callback) => {
    if (playerContainerRef.current?.player && playerReady && !isLoading) {
      try {
        await callback(playerContainerRef.current.player);
      } catch (error) {
        console.error('Error calling player method:', error);
      }
    }
  };

  useEffect(() => {
    let player;
    let isCancelled = false;

    const initializePlayer = async () => {
      if (playerContainerRef.current) {
        if (playerContainerRef.current.player) {
          try {
            await playerContainerRef.current.player.destroy();
          } catch (error) {
            console.error('Error destroying player:', error);
          }
          playerContainerRef.current.player = null;
          setPlayerReady(false);
          setIsLoading(true);
        }

        player = new Player(playerContainerRef.current, {
          id: videoId,
          autoplay: true,
          muted: true,
          loop: true,
          controls: false,
          dnt: true,
          vimeo_logo: false,
        });

        try {
          await player.ready();
          if (!isCancelled) {
            setPlayerReady(true);
            playerContainerRef.current.player = player;
            setIsLoading(false);
          }
        } catch (error) {
          console.error('Error initializing player:', error);
        }
      }
    };

    initializePlayer();

    return () => {
      isCancelled = true;
      if (playerContainerRef.current?.player) {
        playerContainerRef.current.player.destroy().catch((error) =>
          console.error('Error destroying player on cleanup:', error)
        );
      }
      setPlayerReady(false);
    };
  }, [videoId]);

  const toggleFullScreen = async () => {
    if (!playerContainerRef.current?.player || !playerReady || isLoading || isUpdating)
      return;

    setIsUpdating(true);
    try {
      const currentTime = await playerContainerRef.current.player.getCurrentTime();

      if (!isFullScreen) {
        await playerContainerRef.current.player.requestFullscreen();
        await playerContainerRef.current.player.loadVideo({
          id: videoId,
          controls: true,
          dnt: true,
        });
      } else {
        await playerContainerRef.current.player.exitFullscreen();
        await playerContainerRef.current.player.loadVideo({
          id: videoId,
          controls: false,
          dnt: true,
        });
      }

      setTimeout(async () => {
        if (playerContainerRef.current?.player) {
          try {
            await playerContainerRef.current.player.setCurrentTime(currentTime);
            await playerContainerRef.current.player.play();
          } catch (error) {
            console.error('Error restoring time and playing:', error);
          }
        }
      }, 400);

      setIsFullScreen(!isFullScreen);
    } catch (error) {
      console.error('Error toggling full-screen mode:', error);
    } finally {
      setIsUpdating(false);
    }
  };
 
  const toggleMute = async () => {
    safeCall(async (player) => {
      const newMuteState = !isMuted;
      await player.setMuted(newMuteState);
      setIsMuted(newMuteState);
    });
  };

  useEffect(() => {
    const handleFullScreenChange = async () => {
      if (!document.fullscreenElement && playerContainerRef.current?.player && playerReady) {
        try {
          const currentTime = await playerContainerRef.current.player.getCurrentTime();
          setIsFullScreen(false);
          await playerContainerRef.current.player.loadVideo({
            id: videoId,
            controls: false,
            dnt: true,
          });
          setTimeout(async () => {
            if (playerContainerRef.current?.player) {
              await playerContainerRef.current.player.setCurrentTime(currentTime);
              await playerContainerRef.current.player.play();
            }
          }, 400);
        } catch (error) {
          console.error('Error handling full-screen exit:', error);
        }
      }
    };

    document.addEventListener('fullscreenchange', handleFullScreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, [playerReady, videoId]);

  return (
    <div className={styles.videoContainer}>
      <div ref={playerContainerRef} className={styles.videoPlayer} />
      <div className={`${styles.controlsContainer} ${styles.controlsDesktop}`}>
        <div className={styles.muteIcon} onClick={toggleMute}>
          {isMuted ? (
            <i className="fa-solid fa-volume-xmark"></i>
          ) : (
            <i className="fa-solid fa-volume-high"></i>
          )}
        </div>
        <svg
          onClick={toggleFullScreen}
          width="29"
          height="29"
          viewBox="0 0 200 200"
          xmlns="http://www.w3.org/2000/svg"
          style={{ transform: 'translateY(6px)', cursor: 'pointer' }}
        >
          <rect width="75" height="25" x="0" y="20" fill="white" />
          <rect width="75" height="25" x="125" y="20" fill="white" />
          <rect width="75" height="25" x="0" y="155" fill="white" />
          <rect width="75" height="25" x="125" y="155" fill="white" />
          <rect width="25" height="65" x="175" y="20" fill="white" />
          <rect width="25" height="65" x="0" y="20" fill="white" />
          <rect width="25" height="65" x="175" y="115" fill="white" />
          <rect width="25" height="65" x="0" y="115" fill="white" />
        </svg>
      </div>
    </div>
  );
};

export default VimeoBackgroundPlayer;
