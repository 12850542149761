import React from 'react';
import { useLanguage } from '../../context/LanguageContext';
import { useState, useEffect, useRef } from 'react';
import styles from "./LanguageSwitcher.module.css";

function LanguageSwitcher({ isBackgroundAdaptive = false }) {
  const { language, changeLanguage } = useLanguage();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const languages = {
    en: 'en',
    fr: 'fr',
  };

  const toggleDropdown = () => {
      setIsOpen(!isOpen); 
  };

  const selectLanguage = (lng) => {
      changeLanguage(lng);
      setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className={`${styles.dropdownContainer} ${isBackgroundAdaptive ? styles.dropdownContainerWhite : ''}`}>
        <div className={`${styles.selectedLanguage}`} onClick={toggleDropdown}>
            <p className={styles.lang}>{languages[language]}</p>
            <span className={`${styles.triangle} ${isOpen ? styles.up : styles.down} ${isBackgroundAdaptive ? styles.triangleWhite : ''}`} />
        </div>
        {isOpen && (
            <div className={styles.dropdown}>
                {Object.keys(languages).map((lng) => (
                    lng !== language && (
                        <div
                            key={lng}
                            className={`${styles.option} ${isBackgroundAdaptive ? styles.optionWhite : ''}`}
                            onClick={() => selectLanguage(lng)}
                        >
                            {languages[lng]}
                        </div>
                    )
                ))}
            </div>
        )}
    </div>
  );
}

export default LanguageSwitcher;