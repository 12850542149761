import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Mosaic.module.css';

const Media = React.lazy(() => import('../Media/Media'));

function Mosaic({ props }) {
  const navigate = useNavigate();
  const mosaicRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(false);

  const handleImageClick = (projectId) => {
    localStorage.setItem('scrollPosition', window.scrollY);
    navigate(`/project/${projectId}`);
  };


  const { firstBlock, secondBlock, secondBlockInverted, thirdBlock, thirdBlockInverted, fourthBlock, fifthBlock } = props;

  useEffect(() => {
    const currentMosaicRef = mosaicRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      {
        root: null,
        rootMargin: '100px',
        threshold: 0.01,
      }
    );

    if (currentMosaicRef) {
      observer.observe(currentMosaicRef);
    }

    return () => {
      if (currentMosaicRef) {
        observer.unobserve(currentMosaicRef);
      }
    };
  }, []);

  useEffect(() => {
    const images = document.querySelectorAll(`.${styles.mediaWrapper} img`);
    const promises = [...images].map((img) =>
      new Promise((resolve) => {
        if (img.complete) resolve();
        img.onload = resolve;
        img.onerror = resolve;
      })
    );

    Promise.all(promises).then(() => {
      setImagesLoaded(true);
      setTimeout(() => setIsMounted(true), 100);
    });
  }, [props]);

  const renderMedia = (item, index, scaleValue = 1.01) => (
    <div
      key={index}
      className={`${styles.mediaWrapper} ${
        isMounted && imagesLoaded ? styles.visible : ''
      }`}
      style={{ transitionDelay: `${index * 0.1}s` }}
    >
      <React.Suspense fallback={<div className={styles.placeholder}></div>}>
        <Media
          src={item.src}
          type="image"
          onClick={() => handleImageClick(item.projectId)}
          overScale
          scaleValue={scaleValue}
        />
      </React.Suspense>
    </div>
  );

  if (!isVisible) {
    return <div ref={mosaicRef} className={styles.mosaicPlaceholder} />;
  }

  return (
    <div
      ref={mosaicRef}
      className={`${styles.mosaic} ${isMounted && imagesLoaded ? styles.visible : ''}`}
    >
      {firstBlock && (
        <div className={styles.firstRowClass}>
          {firstBlock.map((item, index) => renderMedia(item, index, 1.02))}
        </div>
      )}
      {secondBlock && (
        <div className={styles.secondBlock}>
          {secondBlock.firstColumn && (
            <div className={styles.leftPart}>
              {secondBlock.firstColumn.map((item, index) => renderMedia(item, index, 1.01))}
            </div>
          )}
          {secondBlock.rotatedImage && (
            <div className={styles.rightPart}>
              {renderMedia(secondBlock.rotatedImage, 0, 1.01)}
            </div>
          )}
        </div>
      )}
      {secondBlockInverted && (
        <div className={styles.secondBlock}>
          {secondBlockInverted.rotatedImage && (
            <div className={styles.rightPart}>
              {renderMedia(secondBlockInverted.rotatedImage, 0, 1.01)}
            </div>
          )}
          {secondBlockInverted.firstColumn && (
            <div className={styles.leftPart}>
              {secondBlockInverted.firstColumn.map((item, index) => renderMedia(item, index, 1.01))}
            </div>
          )}
        </div>
      )}
      {thirdBlock && (
        <div className={styles.thirdBlock}>
          {thirdBlock.bigImage && (
            <div className={styles.thirdBlockLeft}>
              {renderMedia(thirdBlock.bigImage, 0, 1.01)}
            </div>
          )}
          {thirdBlock.lastColumn && (
            <div className={styles.thirdBlockRight}>
              {thirdBlock.lastColumn.map((item, index) => renderMedia(item, index, 1.01))}
            </div>
          )}
        </div>
      )}
      {thirdBlockInverted && (
        <div className={styles.thirdBlock}>
          {thirdBlockInverted.lastColumn && (
            <div className={styles.thirdBlockRight}>
              {thirdBlockInverted.lastColumn.map((item, index) => renderMedia(item, index, 1.01))}
            </div>
          )}
          {thirdBlockInverted.bigImage && (
            <div className={styles.thirdBlockLeft}>
              {renderMedia(thirdBlockInverted.bigImage, 0, 1.01)}
            </div>
          )}
        </div>
      )}
      {fourthBlock && (
        <div className={styles.fourthBlock}>
          {renderMedia(fourthBlock, 0, 1.01)}
        </div>
      )}
      {fifthBlock && (
        <div className={styles.fifthBlock}>
          <div className={styles.fifthBlockLeft}>
            {renderMedia(fifthBlock.left, 0, 1.01)}
          </div>
          <div className={styles.fifthBlockRight}>
            {renderMedia(fifthBlock.right, 0, 1.01)}
          </div>
        </div>
      )}
    </div>
  );
}

export default React.memo(Mosaic);
