import React, { useState, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import VimeoVideo from '../VimeoVideo/VimeoVideo.js';
import projectsData from '../../data/projects.js';
import styles from './WorkList.module.css';
import workListOrder from "../../data/workListOrder.js";


const ProjectLine = React.memo(({ 
  project, 
  isSelected, 
  onClick, 
  onDoubleClick 
}) => (
  <div
    className={`${styles.line} ${isSelected ? styles.selectedLine : ''}`}
    onClick={onClick}
    onDoubleClick={onDoubleClick}
  >
    <p>{project.customer.toUpperCase()}</p>
    <p>{project.name.toUpperCase()}</p>
    <p>
      {project.director ? project.director[0].toUpperCase() : ""}
    </p>
  </div>
));

ProjectLine.displayName = 'ProjectLine';

const MediaDisplay = React.memo(({ selectedProject }) => {
  if (!selectedProject) return null;

  return  (
    <div className={styles.medias}>
      <div className={styles.videoWrapper}>
        <VimeoVideo 
          videoId={selectedProject.vimeoId} 
          isWorkList
        />
      </div>
    </div>) 
});

MediaDisplay.displayName = 'MediaDisplay';

const WorkList = () => {
  const [selectedProject, setSelectedProject] = useState(projectsData[84]);
  const navigate = useNavigate();

  const handleLineClick = useCallback((project) => {
    setSelectedProject(project);
  }, []);

  const handleDoubleClick = useCallback((projectId) => {
    navigate(`/project/${projectId}`);
  }, [navigate]);

  const sortedProjects = useMemo(() => {
    const orderMap = new Map(workListOrder.map((id, index) => [id, index]));
    
    return [...projectsData].sort((a, b) => {
      const indexA = orderMap.has(a.id) ? orderMap.get(a.id) : Infinity;
      const indexB = orderMap.has(b.id) ? orderMap.get(b.id) : Infinity;
      return indexA - indexB;
    });
  }, []);

  const projectList = useMemo(() => (
    <div className={styles.list}>
      {sortedProjects.map((project) => (
        <ProjectLine
          key={project.id}
          project={project}
          isSelected={selectedProject === project}
          onClick={() => handleLineClick(project)}
          onDoubleClick={() => handleDoubleClick(project.id)}
        />
      ))}
    </div>
  ), [selectedProject, handleLineClick, handleDoubleClick, sortedProjects]);

  return (
    <div className={styles.mainWrapper}>
      <MediaDisplay selectedProject={selectedProject} />
      {projectList}
    </div>
  );
};

export default React.memo(WorkList);
