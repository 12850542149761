import React from 'react';
import styles from './LoadingSpinner.module.css'; // Import the styles

const Spinner = ({ color = '#bcb6b6' }) => {
  return (
    <div className={styles.spinnerContainer}>
      <div className={styles.spinner} style={{ borderColor: `${color} transparent transparent transparent` }}></div>
    </div>
  );
};

export default Spinner;