export const rolePriority = [
    "",
    "EDITOR",
    "EDITORS",
    "GRADING",
    "COLOR GRADING",
    "VFX SUPERVISOR",
    "LEAD VFX",
    "LEAD COMPOSITING",
    "VFX",
    "VFX ARTISTS",
    "VFX ANIMATORS",
    "VFX / AFTER EFFECTS",
    "COMPOSITING",
    "CGI ARTIST",
    "CGI ARTISTS",
    "2D ARTIST/AFTER EFFECTS",
    "3D ARTISTS",
    "3D ARTIST",
    "3D ANIMATION",
    "3D SUPERVISOR",
    "3D ARTIST & SUPERVISOR",
    "AFTER EFFECTS",
    "AFTER EFFECTS ARTIST",
    "AFTER EFFECTS ARTISTS",
    "ANIMATOR",
    "DEVELOPER",
    "ILLUSTRATIONS",
    "MATTE PAINTING",
    "NUKE ARTIST",
    "NUKE ARTISTS",
    "ROTOSCOPING",
    "ROTOSCOPING / AFTER EFFECTS",
    "TRACKING",
    "LEAD FLAME ARTIST",
    "FLAME",
    "FLAME ARTIST",
    "FLAME ARTISTS",
    "FLAME AT REEPOST STUDIO",
    "ASSISTANT EDITOR",
    "ASSISTANT EDITOR / CONFORMATION",
    "POST-PRODUCER",
    "POST-PRODUCER(F)",
    "POST-PRODUCER(S)",
    "POST-PRODUCTION", 
    "AGENCY",
    "ARTISTIC DIRECTION",
    "DIRECTOR",
    "D.O.P",
    "MUSIC",
    "PRODUCTION",
  ]

export default rolePriority