import projectsData from './projects.js';

const MOSAIC_CONFIGS = {
    mosaic0: {
      firstBlock: [85, 86, 17, 16].map(id => ({
        src: projectsData.find(project => project.id === id).mosaicImg,
        projectId: id
      })),
      fifthBlock: {
        left: {
          src: projectsData.find(project => project.id === 117).mosaicImg,
          projectId: 117
        },
        right: {
          src: projectsData.find(project => project.id === 34).mosaicImg,
          projectId: 34
        }
      }
    },
    mosaic01: {
      fifthBlock: {
        left: {
          src: projectsData.find(project => project.id === 121).mosaicImg,
          projectId: 121
        },
        right: {
          src: projectsData.find(project => project.id === 93).mosaicImg,
          projectId: 93
        }
      }
    },
    mosaic1: {
      secondBlock: {
        firstColumn: [27, 24, 18].map(id => ({
          src: projectsData.find(project => project.id === id).mosaicImg,
          projectId: id
        })),
        rotatedImage: {
          src: projectsData.find(project => project.id === 10).mosaicImg,
          projectId: 10
        }
      },
      thirdBlock: {
        bigImage: {
          src: projectsData.find(project => project.id === 7).mosaicImg,
          projectId: 7
        },
        lastColumn: [4, 5].map(id => ({
          src: projectsData.find(project => project.id === id).mosaicImg,
          projectId: id
        }))
      },
      fourthBlock: {
        src: projectsData.find(project => project.id === 3).mosaicImg,
        projectId: 3
      },
      fifthBlock: {
        left: {
          src: projectsData.find(project => project.id === 14).mosaicImg,
          projectId: 14
        },
        right: {
          src: projectsData.find(project => project.id === 22).mosaicImg,
          projectId: 22
        }
      }
    },
    mosaic2: {
      fourthBlock: {
        src: projectsData.find(project => project.id === 15).mosaicImg,
        projectId: 15
      }
    },
    mosaic3: {
      firstBlock: [29, 8, 13, 11].map(id => ({
        src: projectsData.find(project => project.id === id).mosaicImg,
        projectId: id
      })),
    },
    mosaic31: {
      firstBlock: [28, 21, 57, 88].map(id => ({
        src: projectsData.find(project => project.id === id).mosaicImg,
        projectId: id
      })),
      secondBlockInverted: {
        firstColumn: [26, 1].map(id => ({
          src: projectsData.find(project => project.id === id).mosaicImg,
          projectId: id
        })),
        rotatedImage: {
          src: projectsData.find(project => project.id === 89).mosaicImg,
          projectId: 89
        }
      },
      
      fourthBlock: {
        src: projectsData.find(project => project.id === 25).mosaicImg,
        projectId: 25
      }
    },
    mosaic4: {
      secondBlock: {
        firstColumn: [6, 92, 109].map(id => ({
          src: projectsData.find(project => project.id === id).mosaicImg,
          projectId: id
        })),
        rotatedImage: {
          src: projectsData.find(project => project.id === 54).mosaicImg,
          projectId: 54
        }
      },
      fourthBlock: {
        src: projectsData.find(project => project.id === 19).mosaicImg,
        projectId: 19
      }
    },
    mosaic5: {
      thirdBlock: {
        bigImage: {
          src: projectsData.find(project => project.id === 30).mosaicImg,
          projectId: 30
        },
        lastColumn: [87, 83].map(id => ({
          src: projectsData.find(project => project.id === id).mosaicImg,
          projectId: id
        }))
      }
    },
    mosaic6: {
      firstBlock: [12, 31, 32, 62].map(id => ({
        src: projectsData.find(project => project.id === id).mosaicImg,
        projectId: id
      }))
    },
    mosaic6_1: {
      fourthBlock: {
        src: projectsData.find(project => project.id === 80).mosaicImg,
        projectId: 80
      }
    },
    mosaic6_2: {
      thirdBlock: {
        bigImage: {
          src: projectsData.find(project => project.id === 52).mosaicImg,
          projectId: 52
        },
        lastColumn: [58, 40].map(id => ({
          src: projectsData.find(project => project.id === id).mosaicImg,
          projectId: id
        }))
      }
    },
    mosaic7: {
      firstBlock: [75, 66, 51, 48].map(id => ({
        src: projectsData.find(project => project.id === id).mosaicImg,
        projectId: id
      })),
      thirdBlock: {
        bigImage: {
          src: projectsData.find(project => project.id === 23).mosaicImg,
          projectId: 23
        },
        lastColumn: [20, 69].map(id => ({
          src: projectsData.find(project => project.id === id).mosaicImg,
          projectId: id
        }))
      },
      fourthBlock: {
        src: projectsData.find(project => project.id === 55).mosaicImg,
        projectId: 55
      }
    },
    mosaic8: {
      secondBlockInverted: {
        firstColumn: [68, 77, 64].map(id => ({
          src: projectsData.find(project => project.id === id).mosaicImg,
          projectId: id
        })),
        rotatedImage: {
          src: projectsData.find(project => project.id === 37).mosaicImg,
          projectId: 37
        }
      },
      fourthBlock: {
        src: projectsData.find(project => project.id === 61).mosaicImg,
        projectId: 61
      }
    },
    mosaic9: {
      firstBlock: [38, 67, 71, 42].map(id => ({
        src: projectsData.find(project => project.id === id).mosaicImg,
        projectId: id
      }))
    },

    ////////
    mosaic10: {
      thirdBlock: {
        bigImage: {
          src: projectsData.find(project => project.id === 33).mosaicImg,
          projectId: 33
        },
        lastColumn: [72, 73].map(id => ({
          src: projectsData.find(project => project.id === id).mosaicImg,
          projectId: id
        }))
      },
    },
    mosaic11: {
      firstBlock: [45, 41, 43, 53].map(id => ({
        src: projectsData.find(project => project.id === id).mosaicImg,
        projectId: id
      })),
      
    },
    mosaic12: {
      fourthBlock: {
        src: projectsData.find(project => project.id === 90).mosaicImg,
        projectId: 90
      },
    },
    mosaic13: {
      firstBlock: [50, 35, 36, 82].map(id => ({
        src: projectsData.find(project => project.id === id).mosaicImg,
        projectId: id
      })),
    },
    /////////////
    mosaic14: {
      thirdBlock: {
        bigImage: {
          src: projectsData.find(project => project.id === 46).mosaicImg,
          projectId: 46
        },
        lastColumn: [60, 96].map(id => ({
          src: projectsData.find(project => project.id === id).mosaicImg,
          projectId: id
        }))
      },
    },

    mosaic142: {
      firstBlock: [59, 91, 65].map(id => ({
        src: projectsData.find(project => project.id === id).mosaicImg,
        projectId: id
      })),
    },
    mosaic143: {
      firstBlock: [94, 105, 104].map(id => ({
        src: projectsData.find(project => project.id === id).mosaicImg,
        projectId: id
      })),
      fourthBlock: {
        src: projectsData.find(project => project.id === 101).mosaicImg,
        projectId: 101
      },
    },
    mosaic144: {
      secondBlockInverted: {
        firstColumn: [97, 70].map(id => ({
          src: projectsData.find(project => project.id === id).mosaicImg,
          projectId: id
        })),
        rotatedImage: {
          src: projectsData.find(project => project.id === 102).mosaicImg,
          projectId: 102
        }
      },
    },
    mosaic15: {
      thirdBlock: {
        bigImage: {
          src: projectsData.find(project => project.id === 99).mosaicImg,
          projectId: 99
        },
        lastColumn: [103, 108].map(id => ({
          src: projectsData.find(project => project.id === id).mosaicImg,
          projectId: id
        }))
      },
    },
    mosaic16: {
      thirdBlock: {
        lastColumn: [63, 76].map(id => ({
          src: projectsData.find(project => project.id === id).mosaicImg,
          projectId: id
        })),
        bigImage: {
          src: projectsData.find(project => project.id === 56).mosaicImg,
          projectId: 56
        },
      },
      fourthBlock: {
        src: projectsData.find(project => project.id === 110).mosaicImg,
        projectId: 110
      },
    }
  };
  
  export default MOSAIC_CONFIGS;

  