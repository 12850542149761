import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, NavLink } from "react-router-dom";
import styles from './HomePage.module.css';
import logoWhite from '../assets/logoFilledWhite.png';

const HomePage = () => {
  const [state, setState] = useState({
    isMuted: true,
    isNavLinkVisible: true,
    isFullscreen: false,
    isPaused: false,
    isPortrait: window.innerHeight > window.innerWidth
  });

  const videoRef = useRef(null);
  const navLinkRef = useRef(null);
  const timeoutRef = useRef(null);
  const wasPlayingRef = useRef(true);
  const navigate = useNavigate();

  const mediaBaseUrl = process.env.REACT_APP_MEDIA_BASE_URL || '';
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const isMobile = /iPhone|iPad|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  const videos = {
    landscape: `${mediaBaseUrl}/ST-LOUIS_SHOWREEL.mp4`,
    portrait: `${mediaBaseUrl}/ST-LOUIS_SHOWREEL_9x16.mp4`
  };

  const handleOrientationChange = () => {
    const isPortrait = window.innerHeight > window.innerWidth;
    setState(prev => ({ ...prev, isPortrait }));

    const currentTime = videoRef.current?.currentTime || 0;
    const wasPlaying = videoRef.current ? !videoRef.current.paused : true;

    if (videoRef.current) {
      const handleLoadedMetadata = () => {
        videoRef.current.currentTime = currentTime;
        if (wasPlaying) {
          videoRef.current.play().catch(err =>
            console.error('Error resuming video after orientation change:', err)
          );
        }
        videoRef.current.removeEventListener('loadedmetadata', handleLoadedMetadata);

      };

      videoRef.current.addEventListener('loadedmetadata', handleLoadedMetadata);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleOrientationChange);
    return () => window.removeEventListener('resize', handleOrientationChange);
  }, []);

  useEffect(() => {
    const preloadVideos = () => {
      Object.values(videos).forEach(src => {
        const link = document.createElement('link');
        link.rel = 'preload';
        link.as = 'video';
        link.href = src;
        document.head.appendChild(link);
      });
    };
    preloadVideos();
  }, []);

  const setRandomVideoStartTime = () => {
    const video = videoRef.current;
    if (video?.duration) {
      const maxTime = (video.duration / 4) * 3;
      video.currentTime = Math.random() * maxTime;
    }
  };

  const handleFullscreen = async () => {
    if (!videoRef.current) return;

    try {
      if (isMobile) {
        await handleMobileFullscreen();
        videoRef.current.play()
      } else {
        await handleDesktopFullscreen();
      }
    } catch (err) {
      console.error('Fullscreen error:', err);
      if (isIOS && videoRef.current) {
        videoRef.current.play()
          .catch(err => console.error('Error playing video in fallback:', err));
      }
    }
  };

  const handleMobileFullscreen = async () => {
    const video = videoRef.current;
    if (!video) return;

    if (isIOS) {
      video.controls = true;
      await video.webkitEnterFullscreen();
      setTimeout(() => {
        if (video) {
          video.controls = false;
          video.play().catch(err => console.error('Error resuming video on iOS:', err));
        }
      }, 100);
    } else {
      const wasPlaying = !video.paused;
      wasPlayingRef.current = wasPlaying;

      if (!document.fullscreenElement) {
        await (video.requestFullscreen?.() || video.webkitRequestFullscreen?.());
        video.play().catch(err => console.error('Error resuming video on Android:', err));
      } else {
        wasPlayingRef.current = wasPlaying;

        const handleFullscreenExit = () => {
          if (wasPlaying) {
            const attemptPlay = async () => {
              try {
                await video.play();
                video.controls = false;
              } catch (err) {
                console.error('Error resuming video after fullscreen exit:', err);
              }
            };

            attemptPlay();
            setTimeout(attemptPlay, 100);
            setTimeout(attemptPlay, 300);
            setTimeout(attemptPlay, 500);
          }

          document.removeEventListener('fullscreenchange', handleFullscreenExit);
          document.removeEventListener('webkitfullscreenchange', handleFullscreenExit);
        };

        document.addEventListener('fullscreenchange', handleFullscreenExit);
        document.addEventListener('webkitfullscreenchange', handleFullscreenExit);

        await (document.exitFullscreen?.() || document.webkitExitFullscreen?.());
      }
    }
  };

  const handleDesktopFullscreen = async () => {
    if (!document.fullscreenElement) {
      await videoRef.current.requestFullscreen();
    } else {
      await document.exitFullscreen();
    }
  };

  const handleMouseMove = () => {
    if (!state.isFullscreen) {
      setState(prev => ({ ...prev, isNavLinkVisible: true }));
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() =>
        setState(prev => ({ ...prev, isNavLinkVisible: false })), 1500
      );
    }
  };

  const toggleMute = () => {
    setState(prev => ({ ...prev, isMuted: !prev.isMuted }));
    if (videoRef.current) {
      videoRef.current.muted = !state.isMuted;
    }
  };

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.addEventListener('loadedmetadata', setRandomVideoStartTime);
    }
  }, []);

  useEffect(() => {
    const navLink = navLinkRef.current;

    const handleAnimationEnd = (e) => {
      if (e.animationName === 'fadeOutDown') {
        navLink.style.display = 'none';
      }
    };

    if (navLink) {
      navLink.addEventListener('animationend', handleAnimationEnd);
    }

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      if (navLink) {
        navLink.removeEventListener('animationend', handleAnimationEnd);
      }
      clearTimeout(timeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if (state.isNavLinkVisible && navLinkRef.current) {
      navLinkRef.current.style.display = 'inline-block';
    }
  }, [state.isNavLinkVisible]);

  useEffect(() => {
    const video = videoRef.current;
    const handleTouchStart = () => {
      if (video) {
        video.play().catch(err => console.error('Error playing video:', err));
      }
    };

    if (video) {
      video.addEventListener('touchstart', handleTouchStart);
    }

    return () => {
      if (video) {
        video.removeEventListener('touchstart', handleTouchStart);
      }
    };
  }, [videoRef.current]);

  const handlePageClick = (e) => {
    const muteIcon = document.querySelector(`.${styles.muteIcon}`);
    const fullScreenIcon = document.querySelector(`.${styles.fullScreenIcon}`);
    const navLink = document.querySelector(`.${styles.seeMore}`);

    if (!muteIcon.contains(e.target) && !fullScreenIcon.contains(e.target) && !navLink.contains(e.target)) {
      navigate('/work');
    }
  };

  useEffect(() => {
    document.addEventListener('click', handlePageClick);
    return () => {
      document.removeEventListener('click', handlePageClick);
    };
  }, []);

  const renderControls = () => !state.isFullscreen && (
    <div className={`${styles.buttonWrapper} ${state.isNavLinkVisible ? styles.visible : styles.hidden}`}>
      <div className={styles.muteIcon} onClick={toggleMute}>
        <i className={`fa-solid ${state.isMuted ? 'fa-volume-xmark' : 'fa-volume-high'}`} />
      </div>
      <svg
        onClick={handleFullscreen}
        className={styles.fullScreenIcon}
        viewBox="0 0 200 200"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="75" height="25" x="00" y="20" fill="white" />
        <rect width="75" height="25" x="125" y="20" fill="white" />
        <rect width="75" height="25" x="00" y="155" fill="white" />
        <rect width="75" height="25" x="125" y="155" fill="white" />
        <rect width="25" height="65" x="175" y="20" fill="white" />
        <rect width="25" height="65" x="00" y="20" fill="white" />
        <rect width="25" height="65" x="175" y="115" fill="white" />
        <rect width="25" height="65" x="00" y="115" fill="white" />
      </svg>
    </div>
  );

  const renderNavLink = () => !state.isFullscreen && (
    <div className={`${styles.content} ${state.isNavLinkVisible ? styles.visible : styles.hidden}`}>
      <NavLink
        ref={navLinkRef}
        to="/work"
        className={`${styles.seeMore} ${state.isNavLinkVisible ? styles.visible : styles.hidden}`}
      >
        <img className={styles.logoImg} src={logoWhite} alt="Logo" />
      </NavLink>
    </div>
  );

  return (
    <div className={styles.homePage}>
      <div className={styles.videoContainer}>
        <video
          key={state.isPortrait ? 'portrait' : 'landscape'}
          className={styles.backgroundVideo}
          autoPlay
          muted={state.isMuted}
          loop
          playsInline
          x5-video-player-fullscreen="true"
          x5-video-orientation="landscape"
          controls={state.isFullscreen && !isMobile}
          preload="metadata"
          ref={videoRef}
          x5-video-player-type="h5"
          enterKeyHint="enter"
          style={{
            objectFit: 'cover',
            width: '100%',
            height: '100%',
            background: '#000'
          }}
        >
          <source
            src={state.isFullscreen || !state.isPortrait ? videos.landscape : videos.portrait}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className={styles.footWrapper}>
        {renderNavLink()}
        {renderControls()}
      </div>
    </div>
  );
};

export default HomePage;
