import React, { useState } from 'react';  
import Header from "../components/Header/Header.js";
import styles from "./NotFound.module.css"

function NotFound() {
    return (<div>
        <Header />
        <div className={styles.notFoundContainer}> 
            <h1>404 - Page Not Found</h1>
            <p>Sorry, the page you are looking for does not exist.</p>
        </div>
        
    </div>)
}

export default NotFound;

