import React, { createContext, useState, useContext } from 'react';

const LanguageContext = createContext();

export function LanguageProvider({ children }) {
    const [language, setLanguage] = useState('en'); // Default language
  
    const changeLanguage = (lng) => {
      setLanguage(lng);
    };

    return (
      <LanguageContext.Provider value={{ language, changeLanguage }}>
        {children}
      </LanguageContext.Provider>
    );
}

export function useLanguage() {
    return useContext(LanguageContext);
}   