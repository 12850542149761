import { useLanguage } from '../context/LanguageContext.js';
import globalTranslations from '../data/translations.js';
import Header from '../components/Header/Header.js'
import Title from '../components/Title/Title.js'
import styles from './LegalPage.module.css'

function LegalPage() {
    const { language } = useLanguage();
    const translations = globalTranslations.legalPage;
    
    return (<div>
        <Header></Header>
        <Title titleName={translations[language].title} />
        <p className={styles.content} style={{ whiteSpace: 'pre-line' }}>
            <p className={styles.bold}>{translations[language].editor.title}</p>
            <p>{translations[language].editor.content}</p>
        </p>
        <p className={styles.content} style={{ whiteSpace: 'pre-line' }}>
            <p className={styles.bold}>{translations[language].director.title}</p>
            <p>{translations[language].director.content}</p>
        </p>
        <p className={styles.content} style={{ whiteSpace: 'pre-line' }}>
            <p className={styles.bold}>{translations[language].host.title}</p>
            <p>{translations[language].host.content}</p>
        </p>
        <p className={styles.content} style={{ whiteSpace: 'pre-line' }}>
            <p className={styles.bold}>{translations[language].intellectualProperty.title}</p>
            <p>{translations[language].intellectualProperty.content}</p>
        </p>
        <p className={styles.content} style={{ whiteSpace: 'pre-line' }}>
            <p className={styles.bold}>{translations[language].personnaldata.title}</p>
            <p>{translations[language].personnaldata.content}</p>
        </p>
        <p className={styles.content} style={{ whiteSpace: 'pre-line' }}>
            <p className={styles.bold}>{translations[language].cookies.title}</p>
            <p>{translations[language].cookies.content}</p>
        </p>
        {/* <p className={styles.content} style={{ whiteSpace: 'pre-line' }}>
            <p className={styles.bold}>{translations[language].newsletter.title}</p>
            <p>{translations[language].newsletter.content}</p>
        </p> */}
        <p className={styles.content} style={{fontSize: '12px'}}>Copyright 2025 photographies Dan rota </p>
    </div>)
}

export default LegalPage;
