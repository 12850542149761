import React, { useRef, useState, useEffect, forwardRef } from 'react';
import styles from "./Media.module.css";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner.js"
const Media = forwardRef(({ 
  src, 
  type, 
  onClick, 
  showControls = false, 
  autoplay = true, 
  playOnHover = false,
  overScale = false,
  scaleValue = 1.02,
  preload = "metadata"
}, ref) => {
  const internalRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const mobileCheck = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    setIsMobile(mobileCheck);
  }, []);

  const handleLoaded = () => {
    setIsVisible(true);
  };

  const handleClick = (e) => {
    if (onClick) {
      onClick(e.target);
    } else if (isMobile && type === 'video') {
      const video = e.target;
      if (video.paused) {
        video.play().catch(error => console.error('Error playing video:', error));
      } else {
        video.pause();
      }
    }
  };

  const mediaProps = {
    src,
    className: `${styles.content} ${isVisible ? styles.fadeIn : styles.hidden}`,
    onClick: handleClick,
    ref: isMobile ? ref : internalRef,
  };

  if (type === 'video') {
    Object.assign(mediaProps, {
      autoPlay: autoplay && !playOnHover && !isMobile,
      controls: showControls,
      onLoadedData: handleLoaded,
      muted: true,
      loop: true,
      playsInline: true,
      preload: preload,
    });
  } else {
    Object.assign(mediaProps, {
      alt: "visuals",
      onLoad: handleLoaded,
      loading: "lazy"
    });
  }

  return (
    <div
      className={styles.media}
      style={overScale ? { transition: 'transform 0.5s' } : {}}
      onMouseEnter={overScale ? (e) => e.currentTarget.style.transform = `scale(${scaleValue})` : undefined}
      onMouseLeave={overScale ? (e) => e.currentTarget.style.transform = 'scale(1)' : undefined}
    >
      {!isVisible && (
        <div className={styles.spinnerContainer}>
          <LoadingSpinner />
        </div>
      )}
      {type === 'image' ? (
        <img {...mediaProps} />
      ) : (
        <video {...mediaProps} />
      )}
    </div>
  );
});

export default Media;