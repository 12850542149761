const globalTranslations = {
    header: {
        en: {
            work:"WORK",
            about: "ABOUT",
            contact: 'CONTACT',
            showreel: "REELS",
        },
        fr: {
            work:"FILMS",
            about: "À PROPOS",
            contact: 'CONTACT',
            showreel: "REELS",
        },
    },
    aboutPage: {
        en: {
            title: "ABOUT",
            aboutText: 'St-Louis Post House combines talents and technical savoir-faire to translate our clients visions into reality. We strive to deliver refined and high-end contents.',
            teams: 'TEAMS',
            terms: "Terms & Conditions - CGV",
            pps: "Post-producers",
        },
        fr: {
            title: "À PROPOS",
            aboutText: 'Saint Louis combine talent et savoir-faire pour donner vie aux visions de nos clients. Nous nous évertuons à produire des images élégantes et uniques.',
            teams: 'ÉQUIPES',
            terms: "Termes & Conditions - CGV",
            pps: "Post-producteurs"
        },
    },
    contactPage: {
        en: {
            inTouch: "LETS GET IN TOUCH",
            newsletter: "SUBSCRIBE TO OUR NEWSLETTER",
            message: "SEND US A MESSAGE",
            offices: "OUR OFFICE",
            email: "Your email",
            name: "Your name",
            subject: "Subject",
            sendButton: "Send",
        },
        fr: {
            inTouch: "NOUS CONTACTER",
            newsletter: "ABONNEZ-VOUS À NOTRE NEWSLETTER",
            message: "ENVOYEZ NOUS UN MESSAGE",
            offices: "NOS BUREAUX",
            email: "Votre email",
            name: "Votre nom",
            subject: "Objet",
            sendButton: "Envoyer",
        }
    },
    workPage: {
        en: {
            title: "WORK"
        },
        fr: {
            title: "FILMS"
        }
    },
    showreelPage: {
        en: {
            title: "REELS"
        },
        fr: {
            title: "REELS"
        }
    },
    legalPage: {
        en: {
            newsletter: {
                title: 'NEWSLETTER',
                content: 'By subscribing to our newsletter, you are allowing Saint Louis to send you information relating to its events and services. You acknowledge and agree our process of your personal data for the purpose of the newsletter. Please note that you may unsubscribe at any time from our newsletter by clicking on the unsubscribe-link that is on the bottom in each newsletter. For more information, and to exercise your access right, in accordance with the applicable law, please click on the following link: Legal Notice',

            },
            title: "TERMS OF USE",
            editor: {
                title: 'PUBLISHER',
                content: `Department Video (Saint Louis)
                SA with share capitaal of 152 449,02€
                Registered office: 25 rue Henry Monnier, 75009 Paris
                RCS Paris n° 409 493 244
                Contact: lea.villegoureix@st-louis.tv`,
            },
            director: {
                title: `PUBLICATION DIRECTOR`,
                content: `Mr. Patrice HADDAD`
            },
            host: {
                title: `HOST`,
                content: `OVHCLOUD
                SASU with share capital of 50.000.000€
                Registered office: 2 RUE KELLERMANN, 59100 ROUBAIX France`,
            },
            intellectualProperty: {
                title: `RIGHTS AND INTELLECTUAL PROPERTY`,
                content: `Saint Louis, its affiliates and/or its partners and/or its clients (in the case of authorized contents) are the exclusive owners of all intellectual property rights covering both the visual interface and the content of the site VIMEO in the entire world. Saint Louis authorizes the user the right to reproduce part of the content in the context of a strictly personal use and only in this context. Any dissemination whatever the medium is strictly prohibited without the prior authorization of Saint Louis. Violation of these requirements subjects the offender to criminal and civil penalties under French law.`,
            },
            personnaldata: {
                title: `DATA PRIVACY`,
                content: `Nominative data (surnames, first names, contact details, etc.) or non-nominative data (preferences, favorite information, etc.) may be requested when visiting the st-louis.tv website in order to offer personalized services (such as newsletters from which you can opt out at any time) or to respond to any requests for contact via the contact tab. In accordance with the French Data Protection Law of January 6th 1978 amended on June 1st 2019 to include the provisions of the General Data Protection Regulation (GDPR), users of the site are informed that they have the right to access, modify, rectify and delete their personal data at any time at the following address: privacy@premiere-heure.fr Saint Louis is recipient of personal data and has access to his data by its website and does not transfert hem to any third party.`,
            },
            cookies: {
                title: `COOKIES`,
                content: `Saint Louis uses cookies in the form of files which are stored on your personal computer allowing an easy access to the services offered. These cookies are not used to collect any personal data without express consent. Saint Louis, it's affiliates, and its partners shall not share any personal data and will not use it for unsolicited marketing purposes.`,
            }
        },
        fr: {
            newsletter: {
                title: 'NEWSLETTER',
                content: 'En vous inscrivant à notre newsletter, vous certifiez vouloir recevoir l’actualité de Saint Louis, des informations sur des évènements et services de Saint Louis. Vous reconnaissez et acceptez la collecte de vos données personnelles dans le cadre de cette newsletter. Vous pouvez à tout moment vous désinscrire de notre newsletter en cliquant sur le lien situé en bas de chaque newsletter. Pour plus d’informations, ainsi que pour exercer votre droit d’accès, conformément à la loi applicable, veuillez cliquer sur le lien suivant: Mentions Légales',

            },
            title: "MENTIONS LÉGALES",
            editor: {
                title: 'EDITEUR',
                content: `Department Video (Saint Louis)
                SA au capital de 152 449,02€
                Siège social : 25 rue Henry Monnier, 75009 Paris
                RCS de Paris n° 409 493 244
                Contact : lea.villegoureix@st-louis.tv`,
            },
            director: {
                title: `DIRECTEUR DE LA PUBLICATION`,
                content: `Monsieur Patrice HADDAD`
            },
            host: {
                title: `HEBERGEUR`,
                content: `OVHCLOUD
                SASU au capital de 50.000.000€
                Siège social:  2 RUE KELLERMANN, 59100 ROUBAIX France`,
            },
            intellectualProperty: {
                title: `DROITS ET PROPRIETE INTELLECTUELLE`,
                content: `Saint Louis, ses filiales, et/ou ses partenaires et/ou ses clients (dans le cas de contenus autorisés), sont les titulaires exclusifs de tous les droits de propriété intellectuelle portant tant sur l’interface visuelle que sur le contenu du site VIMEO et ce, dans le monde entier. Saint Louis autorise une extraction exclusivement réservée à un usage strictement personnel, non collectif et non commercial. Toute diffusion du contenu du site quel que soit le support est strictement interdite sans l’autorisation préalable de Saint Louis. La violation de ces exigences soumet le contrevenant aux peines pénales et civiles prévues dans ce cas par la loi française.`,
            },
            personnaldata: {
                title: `DONNEES PERSONNELLES`,
                content: `Des données nominatives (noms, prénoms, coordonnées…) ou non nominatives (préférences, informations favorites…) pourront être demandées lors de la visite sur le site internet st-louis.tv afin de proposer des services personnalisés (tels que newsletters auxquelles vous pourrez vous désinscrire à tout moment) ou pour répondre à toute demande de mise en relation via l’onglet contact. Conformément à la loi «Informatique et libertés» du 6 janvier 1978 modifiée au 1 er juin 2019, afin d’inclure les dispositions du règlement général sur la protection des données (RGPD), l’utilisateur du site est informé qu’il dispose à tout moment d’un droit d’accès, de modification, de rectification et de suppression des données personnelles le concernant à l’adresse suivante: privacy@premiere-heure.fr Saint Louis est destinataire des données personnelles recueillies par l’intermédiaire de son site internet et ne les transmet en aucun cas à des tiers.`,
            },
            cookies: {
                title: `COOKIES`,
                content: `Le site de Saint Louis utilise des cookies qui sont des fichiers stockés sur votre ordinateur permettant une facilité d’accès aux services proposés. Les cookies de ce site ne pourront pas servir à collecter des données personnelles sans votre accord exprès. Ces informations ne seront pas transmises en dehors de Saint Louis, de ses filiales et de ses partenaires, et ne seront pas utilisées dans le cadre de campagnes de communication non sollicitées.`,
            }
        },
    },
    credits: {
        en: {
            "2D ARTIST/AFTER EFFECTS": "2D ARTIST/AFTER EFFECTS",
            "3D ARTISTS": "3D ARTISTS",
            "3D ARTIST": "3D ARTIST",
            "3D ANIMATION": "3D ANIMATION",
            "3D SUPERVISOR": "3D SUPERVISOR",
            "3D ARTIST & SUPERVISOR": "3D ARTIST & SUPERVISOR",
            "AFTER EFFECTS": "AFTER EFFECTS",
            "AFTER EFFECTS ARTIST": "AFTER EFFECTS ARTIST",
            "AFTER EFFECTS ARTISTS": "AFTER EFFECTS ARTISTS",
            "AGENCY": "AGENCY",
            "ANIMATOR": "ANIMATOR",
            "ARTISTIC DIRECTION": "ARTISTIC DIRECTION",
            "ASSISTANT EDITOR": "ASSISTANT EDITOR",
            "ASSISTANT EDITOR / CONFORMATION": "ASSISTANT EDITOR / CONFORMATION",
            "COLOR GRADING": "COLOR GRADING",
            "COMPOSITING": "COMPOSITING",
            "CGI ARTIST": "CGI ARTIST",
            "CGI ARTISTS": "CGI ARTISTS",
            "DIGITAL EDITOR": "DIGITAL EDITOR",
            "DIRECTOR": "DIRECTOR",
            "DEVELOPER": "DEVELOPER",
            "D.O.P": "D.O.P",
            "EDITOR": "EDITOR",
            "EDITORS": "EDITORS",
            "LEAD FLAME ARTIST": "LEAD FLAME ARTIST",
            "LEAD VFX": "LEAD VFX",
            "LEAD COMPOSITING": "LEAD COMPOSITING",
            "FLAME": "FLAME",
            "FLAME ARTIST": "FLAME ARTIST",
            "FLAME ARTISTS": "FLAME ARTISTS",
            "FLAME AT REEPOST STUDIO": "FLAME AT REEPOST STUDIO",
            "GRADING": "GRADING",
            "ILLUSTRATIONS":"ILLUSTRATIONS",
            "MATTE PAINTING":"MATTE PAINTING",
            "MUSIC": "MUSIC",
            "NUKE ARTIST": "NUKE ARTIST",
            "NUKE ARTISTS": "NUKE ARTISTS",
            "POST-PRODUCER": "POST-PRODUCER",
            "POST-PRODUCER(F)": "POST-PRODUCER",
            "POST-PRODUCER(S)": "POST-PRODUCERS",
            "POST-PRODUCTION": "POST-PRODUCTION",
            "PRODUCTION": "PRODUCTION",
            "ROTOSCOPING": "ROTOSCOPING",
            "ROTOSCOPING / AFTER EFFECTS":"ROTOSCOPING / AFTER EFFECTS",
            "TRACKING": "TRACKING",
            "VFX": "VFX",
            "VFX ARTISTS": "VFX ARTISTS",
            "VFX ANIMATORS": "VFX ANIMATORS",
            "VFX / AFTER EFFECTS": "VFX / AFTER EFFECTS",
            "VFX SUPERVISOR": "VFX SUPERVISOR",
        },
        fr: {
            "2D ARTIST/AFTER EFFECTS": "ARTISTE AFTER EFFECTS",
            "3D ARTISTS": "ARTISTES 3D",
            "3D ARTIST": "ARTISTE 3D",
            "3D ANIMATION": "ARTISTE ANIMATION 3D",
            "3D ARTIST & SUPERVISOR": "ARTISTE & SUPERVISEUR 3D",
            "3D SUPERVISOR": "SUPERVISEUR 3D",
            "AFTER EFFECTS": "AFTER EFFECTS",
            "AFTER EFFECTS ARTIST": "ARTISTE AFTER EFFECTS",
            "AFTER EFFECTS ARTISTS": "ARTISTES AFTER EFFECTS",
            "AGENCY": "AGENCE",
            "ANIMATOR": "ANIMATION",
            "ARTISTIC DIRECTION": "DIRECTION ARTISTIQUE",
            "ASSISTANT EDITOR": "ASSISTANT MONTEUR",
            "ASSISTANT EDITOR / CONFORMATION": "ASSISTANT MONTAGE / CONFORMATION",
            "COLOR GRADING": "COLOR GRADING",
            "COMPOSITING": "COMPOSITING",
            "CGI ARTIST": "",
            "CGI ARTISTS": "ARTISTES CGI",
            "DIGITAL EDITOR": "MONTEUR DIGITAL",
            "DIRECTOR": "",
            "DEVELOPER": "DEVELOPPEUR",
            "D.O.P": "",
            "EDITOR": "MONTEUR",
            "EDITORS": "MONTEURS",
            "LEAD FLAME ARTIST": "SUPERVISEUR ARTISTE FLAME ",
            "LEAD VFX": "LEAD VFX",
            "LEAD COMPOSITING": "LEAD COMPOSITING",
            "FLAME": "FLAME",
            "FLAME ARTIST": "ARTISTE FLAME",
            "FLAME ARTISTS": "ARTISTES FLAME",
            "FLAME AT REEPOST STUDIO": "FLAME AUX STUDIOS REEPOST",
            "GRADING": "ÉTALONNEUR",
            "ILLUSTRATIONS": "ARTISTE ILLUSTRATEUR",
            "MATTE PAINTING":"MATTE PAINTING",
            "MUSIC": "MUSIQUE",
            "NUKE ARTIST": "ARTISTE NUKE",
            "NUKE ARTISTS": "ARTISTES NUKE",
            "POST-PRODUCER": "DIRECTEUR DE POST-PRODUCTION",
            "POST-PRODUCER(F)": "DIRECTRICE DE POST-PRODUCTION",
            "POST-PRODUCER(S)": "DIRECTEURS DE POST-PRODUCTION",
            "POST-PRODUCTION": "POST-PRODUCTION",
            "PRODUCTION": "PRODUCTION",
            "ROTOSCOPING": "ROTOSCOPIE",
            "ROTOSCOPING / AFTER EFFECTS": "ROTOSCOPIE / AFTER EFFECTS",
            "TRACKING": "TRACKING",
            "VFX": "VFX",
            "VFX ARTISTS": "ARTISTES VFX",
            "VFX ANIMATORS": "ANIMATEURS VFX",
            "VFX / AFTER EFFECTS": "VFX / AFTER EFFECTS",
            "VFX SUPERVISOR": "SUPERVISEUR VFX",
        },
    },
    projectPage: {
        en: {
            visuals: 'SELECTED VISUALS',
            director: 'DIRECTOR',
            agency: 'AGENCY'
        },
        fr: {
            visuals: 'SÉLECTION',
            director: 'RÉALISATEUR',
            agency: 'AGENCE'
        }
    }
}

export default globalTranslations;