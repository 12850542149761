import Title from "../components/Title/Title"
import { useRef, useEffect, useState } from 'react';
import styles from './AboutPage.module.css'; 
import teamData from '../data/team.js'
import Header from "../components/Header/Header.js";
import { useLanguage } from '../context/LanguageContext.js';
import globalTranslations from '../data/translations.js'
import { NavLink } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Modal from 'react-modal';

Modal.setAppElement('#root');

function AboutPage() {
    const { language } = useLanguage();
    const translations = globalTranslations.aboutPage;

    const sliderImages = [
        '1slide.jpg',
        '2slide.jpg',
        '3slide.jpg',
        '4slide.jpg',
        '5slide.jpg',
        '6slide.jpg',
        '7slide.jpg',
        '8slide.jpg',
        '9slide.jpg',
    ];


    const carouselRef = useRef(null);

    const handleImageLoad = (index) => {
        setImageLoaded((prevState) => {
            const newState = [...prevState];
            newState[index] = true;
            return newState;
        });
    };

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1224 },
            items: 5
        },
        tablet: {
            breakpoint: { max: 1224, min: 921 },
            items: 3
        },
        mobileXL: {
            breakpoint: { max: 920, min: 621 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 620, min: 0 },
            items: 1
        }
    };

    const mediaBaseUrl = process.env.REACT_APP_MEDIA_BASE_URL || '';
    const images = sliderImages.map(image => `${mediaBaseUrl}/SLIDER/${image}`);
    const [imageLoaded, setImageLoaded] = useState(Array(images.length).fill(false));
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState('');
    const [lastClickTime, setLastClickTime] = useState(null);

    const handleDoubleClick = (image) => {
        const currentTime = new Date().getTime();
        if (lastClickTime && currentTime - lastClickTime < 500) {
            if (window.innerWidth > 620) {
                setIsModalOpen(true);
                setCurrentImage(image);
            }
        }
        setLastClickTime(currentTime);
    };

    return (
        <div>
            <Header />
            <Title titleName={translations[language].title}/>
            <div ref={carouselRef}>
                <Carousel 
                    additionalTransfrom={0}
                    arrows={false}
                    autoPlay={true}
                    autoPlaySpeed={4500}
                    infinite
                    containerClass={styles.container}
                    sliderClass={styles.slider}
                    itemClass={styles.slide}                      
                    transitionDuration={700}
                    draggable={true}
                    swipeable={true}
                    minimumTouchDrag={50}
                    partialVisbile
                    focusOnSelect={false}
                    renderDotsOutside
                    keyBoardControl
                    renderArrowsWhenDisabled={false}
                    renderButtonGroupOutside={false}
                    responsive={responsive}
                    rewind={false}
                    rewindWithAnimation={false}
                    rtl={false}
                    shouldResetAutoplay
                    slidesToSlide={1}                  
                >
                    {images.map((image, index) => (
                        <img 
                            src={image} 
                            draggable={false} 
                            className={`${styles.image} ${imageLoaded[index] ? styles.loaded : ''}`} 
                            alt={`Slide ${index + 1}`} 
                            key={index}
                            onLoad={() => handleImageLoad(index)}
                            onClick={() => handleDoubleClick(image)}
                        />
                    ))}
                </Carousel>
            </div>
            <Modal 
                isOpen={isModalOpen} 
                onRequestClose={() => setIsModalOpen(false)} 
                contentLabel="Image Modal"
                style={{
                    overlay: {
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.75)'
                    },
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        padding: '0px',
                        width: '95%',
                        maxHeight: '95%',
                        overflow: 'hidden',
                        maxWidth: '1000px',
                        background: 'none',
                        border: '1px solid #ccc',
                        borderRadius: '5px',
                        boxShadow: '0 0 0px rgba(0,0,0,0.2)'
                    }
                }}
            >
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <button onClick={() => setIsModalOpen(false)} style={{ 
                        position: 'absolute',
                        top: '0px',
                        right: '5px',
                        fontSize: '24px', 
                        fontWeight: 'bold', 
                        cursor: 'pointer',                        
                        border: 'none',
                        zIndex: '500000',
                        color: 'white',
                        background: 'none'
                    }}>&times;</button>
                </div>
                <img 
                    src={currentImage} 
                    alt="Modal Image"
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain'
                    }}
                />
            </Modal>

            <div className={styles.description}>

            </div>
            <div className={styles.detailsWrapper}>
                <div className={styles.services}>
                    <div className={styles.detailsTitle}>SERVICES</div>
                    <div className={styles.firstServices}>
                        <p>3D</p>
                        <p>Editing</p>
                        <p>Grading</p>
                    </div>
                    <div className={styles.secondServices}>
                        <p>Flame</p>
                        <p>2D / Digital</p>
                        <p>Compositing</p>
                    </div>
                </div>
                <div className={styles.teams}>
                    <div className={styles.detailsTitle}>{translations[language].teams}</div>
                    <div className={styles.teamsWrapper}>
                        {teamData.map((team, index) => (
                            <div key={index} className={styles.columns}>
                                {/* Column 1 */}
                                <div className={styles.column}>
                                {team.filter(member => member.col === 1).map((member, i) => (
                                    <div key={i} className={styles.teamMember}>
                                        <p className={styles.role}>{member.role[language]}</p>
                                        <p className={styles.name}>{member.name}</p>
                                    </div>
                                ))}
                                </div>
                                {/* Column 2 */}
                                <div className={styles.column}>
                                {team.filter(member => member.col === 2).map((member, i) => (
                                    <div key={i} className={styles.teamMember}>
                                        <p className={styles.role}>{member.role[language]}</p>
                                        <p className={styles.name}>{member.name}</p>
                                    </div>
                                ))}
                                </div>
                            </div>                   
                        ))}
                    </div>
                </div>
            </div>
            <div className={styles.termsContainer}>
                <NavLink to="/legal"><p className={styles.legalLink}>{translations[language].terms}</p></NavLink>
                {/* <p className={styles.legalLink}>"Copyright 2025 DEPARTMENT VIDEO - Tous droits réservés. Les contenus de ce site sont protégés par les lois sur la propriété intellectuelle et ne peuvent être reproduits, distribués ou modifiés sans l'autorisation expresse de DEPARTMENT VIDEO."</p> */}
            </div>
        </div>
      );
}

export default AboutPage;
