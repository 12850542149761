const showreels = [
    {
        id: 1,
        name: "",
        vimeoId: 1054524995,
        year: '',

    },
    /* {
        id: 2,
        name: "SHOWREEL 2",
        vimeoId: 1034457303,
        year: '2024',

    }, */
]


export default showreels
