import React from 'react';


const CVG = ({ pdf }) => {
    return (
        <object
          data={pdf}
          type="application/pdf"
          width="100%"
          height="100%"
        >
          <p>Unable to display PDF file. <a href="path/to/your/pdf.pdf">Download</a> instead.</p>
        </object>
      );
};

export default CVG;