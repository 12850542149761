import React, { useRef, useState, useEffect, forwardRef } from 'react';
import { Route, Routes } from 'react-router-dom';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import HomePage from './pages/HomePage';
import WorkPage from './pages/WorkPage';
import ShowreelPage from './pages/ShowreelPage';
import ProjectPage from './pages/ProjectPage';
import LegalPage from './pages/LegalPage';
import NotFound from './pages/NotFound';
import TestPage from './pages/TestPage';
import CVG from './pages/CGV';
import './App.css';

import CVGfr from "./assets/ConditionsGenerales.pdf"
import CVGen from "./assets/Terms&Conditions.pdf"

const App = () => {

  useEffect(() => {
    const detectWheelBlock = (e) => {
      if (e.defaultPrevented) {
        console.warn("Scroll event blocked by:", e.target);
      }
    };
    window.addEventListener("wheel", detectWheelBlock, { passive: false });
  
    return () => window.removeEventListener("wheel", detectWheelBlock);
  }, []);

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/test" element={<TestPage />} />
      <Route path="/work" element={<WorkPage />} />
      <Route path="/project/:projectId" element={<ProjectPage />} />
      <Route path="/legal" element={<LegalPage />} />
      <Route path="/showreel" element={<ShowreelPage />} />
      <Route path="/wp-content/uploads/2024/06/CGV.pdf" element={<CVG pdf={CVGfr}/>} />
      <Route path="/wp-content/uploads/2024/06/TNC.pdf" element={<CVG pdf={CVGen}/>} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;