const teamData = [

    [{
      name: "Louis Arcelin",
      role: {
        en: 'Founder',
        fr: 'Directeur de post-production',
      },
      col:1
    },
   ],
    [{
      name: "Daniel Rota",
      role: {
        en: 'Post-producer',
        fr: 'Post-producteur',
      },
      col:1
    },
    {
      name: "Jean-Marc Raygade",
      role: {
        en: 'Post-producer',
        fr: 'Post-producteur',
      },
      col:1
    },
    {
      name: "Clément Du Passage",
      role: {
        en: 'Post-producer',
        fr: 'Post-producteur',
      },
      col:1
    },
    {
      name: "Aurélie Lobin",
      role: {
        en: 'Post-producer',
        fr: 'Post-productrice',
      },
      col:1
    },
    ],

  ];
  
export default teamData;
