import React, { useState, useEffect, useMemo } from 'react';
import Media from '../Media/Media';
import styles from './MosaicSmall.module.css';
import { useNavigate } from 'react-router-dom';
import workListOrder from '../../data/workListOrder.js';
import { alternativeMosaic } from '../../data/workListOrder.js';

function MosaicSmall({ projects }) {
    const [hasTransition, setHasTransition] = useState(true);
    const navigate = useNavigate();

    const handleClick = (projectId) => {
        localStorage.setItem('scrollPosition', window.scrollY);
        navigate(`/project/${projectId}`);
    };

    const sortedProjects = useMemo(() => {
        const orderMap = new Map(workListOrder.map((id, index) => [id, index]));

        return [...projects].sort((a, b) => {
            const indexA = orderMap.has(a.id) ? orderMap.get(a.id) : Infinity;
            const indexB = orderMap.has(b.id) ? orderMap.get(b.id) : Infinity;
            return indexA - indexB;
        });
    }, [projects]);

    const handleScroll = () => {
        setHasTransition(false);
    };

    setTimeout(() => setHasTransition(false), 1000);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={styles.wrapper}>
            {sortedProjects.map((project, index) => (
                <div
                    key={index}
                    id={`card-${index}`}
                    onClick={() => handleClick(project.id)}
                    className={styles.cardWrapper}
                >
                    <div className={styles.mediaWrapper}>
                        <Media
                            src={alternativeMosaic.includes(project.id) ? project.alternativeImg : project.mosaicImg}
                            type={'image'}
                        />
                    </div>
                    <div className={styles.description}>
                        <p className={styles.customer}>{project.customer.toUpperCase()}</p>
                        <p className={styles.name}>{project.name.toUpperCase()}</p>
                        <p className={styles.tags}>
                            {project.tags.map((tag, tagIndex) => (
                                <span key={tagIndex} className={styles.tag}>
                                    {tag.toUpperCase()}
                                    {tagIndex < project.tags.length - 1 && ', '}
                                </span>
                            ))}
                        </p>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default MosaicSmall;