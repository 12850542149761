import React, { useState, useEffect } from 'react';
import styles from './SidePanel.module.css'; // Adjust the path as necessary
import back from '../../assets/back.png'; 
import backBlack from '../../assets/back_black.png'; 

function SidePanel({ height = '100vh', width = '25vw', position = 'left', children, customToggle }) {
    
    const [isOpen, setIsOpen] = useState(false);
    const [isMobileWidth, setIsMobileWidth] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileWidth(window.innerWidth < 930);
        };
        handleResize()
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const togglePanel = () => {
        setIsOpen((prevState) => !prevState);
    };

    const handleToggle = customToggle || togglePanel;

    return (
        <div>
            {!customToggle && (
                <button
                    onClick={handleToggle}
                    className={`${styles.toggleButton} ${styles[position]} ${isMobileWidth ? styles.mobileButton : ""}`}
                >
                    <img src={back} alt="Toggle" />
                </button>
            )}
            <div
                className={`${styles.sidePanel} ${styles[position]} ${isOpen ? styles.open : ''}`}
                style={{ height, width }} 
            >
                <button
                    onClick={handleToggle}
                    className={`${styles.closeButton} ${styles[position]}`}
                >
                    <img src={backBlack} alt="Close" />
                </button>
                <div className={styles.content}>
                    {children}
                </div>
            </div>
        </div>
    );
}

export default SidePanel;
