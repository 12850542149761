import React, { useState, useEffect, useRef, useCallback } from 'react';
import styles from './SelectedVisuals.module.css';
import back from '../../assets/back.png';
import { useLanguage } from '../../context/LanguageContext.js';
import globalTranslations from '../../data/translations.js'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner.js';

const SelectedVisuals = ({ mediaItems }) => {

  const { language } = useLanguage();
  const translations = globalTranslations.projectPage

  const [isMobile, setIsMobile] = useState(false);
  const [isIPad, setIsIPad] = useState(false);
  const mediaRefs = useRef([]);
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
  const [isMobileWidth, setIsMobileWidth] = useState(false);

  const [loadedItems, setLoadedItems] = useState(new Array(mediaItems.length).fill(false));
  
  useEffect(() => {
      const handleResize = () => {
          setIsMobileWidth(window.innerWidth < 930);
      };
      handleResize();
      window.addEventListener('resize', handleResize);
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);

  const checkDevice = useCallback(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const mobileCheck = /iphone|ipad|ipod|android/i.test(userAgent);
    const iPadCheck = /ipad/i.test(userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    setIsMobile(mobileCheck);
    setIsIPad(iPadCheck);
  }, []);

  useEffect(() => {
    checkDevice();
    window.addEventListener('resize', checkDevice);
    return () => window.removeEventListener('resize', checkDevice);
  }, [checkDevice]);

  const preloadVideos = useCallback(() => {
    mediaRefs.current.forEach((media) => {
      if (media && media.tagName === 'VIDEO') {
        media.preload = 'auto';
        media.load();

        if (isIPad) {
          media.play().then(() => {
            media.pause();
            media.currentTime = 0;
          }).catch(error => console.error('Error preloading video:', error));
        }

        media.addEventListener('loadedmetadata', () => {
          media.currentTime = 0.01;
        });
      }
    });
  }, [isIPad]);

  useEffect(() => {
    preloadVideos();
    const currentMediaRefs = mediaRefs.current;
    return () => {
      currentMediaRefs.forEach((media) => {
        if (media && media.tagName === 'VIDEO') {
          media.removeEventListener('loadedmetadata', () => {
            media.currentTime = 0.01;
          });
        }
      });
    };
  }, [preloadVideos, mediaItems]);

  const handleMediaInteraction = useCallback((media, index) => {
    if (media.tagName === 'VIDEO' && currentlyPlaying !== index) {
      mediaRefs.current.forEach((m, i) => {
        if (m && m.tagName === 'VIDEO') {
          if (i === index) {
            m.muted = true;
            m.play().catch(error => console.error('Error playing video:', error));
          } else {
            m.pause();
          }
        }
      });
      setCurrentlyPlaying(index);
    }
  }, [currentlyPlaying]);

  const handleMouseLeave = useCallback((media, index) => {
    if (media && media.tagName === 'VIDEO') {
      media.pause();
      if (currentlyPlaying === index) {
        setCurrentlyPlaying(null);
      }
    }
  }, [currentlyPlaying]);

  function scrollDownBy(amount = 100) {
    window.scrollBy({
      top: amount,
      behavior: 'smooth'
    });
  }

  const handleMediaLoaded = (index) => {
    setLoadedItems(prev => {
      const newLoadedItems = [...prev];
      newLoadedItems[index] = true;
      return newLoadedItems;
    });
  };

  const renderMediaItem = useCallback((item, index) => {
    if (!item) return null;

    const fadeInDelay = `${index * 0.2}s`;
    const isLoaded = loadedItems[index];

    return (
      <div key={index} className={item.type === 'video' ? styles.videoWrapper : styles.imageWrapper}>
        {!isLoaded && (
          <div className={styles.spinnerWrapper}>
            <LoadingSpinner/>
          </div>
        )}
        
        {item.type === 'video' ? (
          <video
            ref={el => mediaRefs.current[index] = el}
            src={item.src}
            className={`${styles.video} ${isLoaded ? styles.fadeIn : styles.hidden}`}
            style={{ animationDelay: fadeInDelay }}
            playsInline
            muted
            loop
            preload="auto"
            onLoadedData={() => handleMediaLoaded(index)}
            onMouseEnter={() => !isMobile && handleMediaInteraction(mediaRefs.current[index], index)}
            onMouseLeave={() => !isMobile && handleMouseLeave(mediaRefs.current[index], index)}
            onClick={() => (isMobile || isIPad) && handleMediaInteraction(mediaRefs.current[index], index)}
          />
        ) : (
          <img
            ref={el => mediaRefs.current[index] = el}
            src={item.src}
            alt={`Project visual ${index + 1}`}
            className={`${styles.image} ${isLoaded ? styles.fadeIn : styles.hidden}`}
            style={{ animationDelay: fadeInDelay }}
            onLoad={() => handleMediaLoaded(index)}
            onMouseEnter={() => setCurrentlyPlaying(null)}
          />
        )}
      </div>
    );
  }, [handleMediaInteraction, handleMouseLeave, isIPad, isMobile, loadedItems]);

  return (
    <div className={styles.mainWrapper}>
      <div className={`${styles.banner} ${isMobileWidth ? styles.bannerMobile : ''}`} onClick={() => scrollDownBy(700)}>
        
        {!isMobileWidth && (
          <div>
            <p>{translations[language].visuals}</p>
            <img className={styles.bannerArrow} src={back} alt="Back Arrow" />
          </div>
        )}
      </div>
      <div className={styles.visualsWrapper}>
        {mediaItems.length > 0 && (
          <div className={styles.visualsFirstRow}>
            <div className={styles.visualFirstChildContainer}>{renderMediaItem(mediaItems[0], 0)}</div>
            <div className={styles.visualSecondChildContainer}>{renderMediaItem(mediaItems[1], 1)}</div>
          </div>
        )}
        {renderMediaItem(mediaItems[2], 2)}
        {mediaItems.length > 3 && (
          <div className={styles.visualsSecondRow}>
            <div className={styles.visualFirstChildContainer}>{renderMediaItem(mediaItems[3], 3)}</div>
            <div className={styles.visualSecondChildContainer}>{renderMediaItem(mediaItems[4], 4)}</div>
          </div>
        )}
        {renderMediaItem(mediaItems[5], 5)}
      </div>
    </div>
  );
};

export default SelectedVisuals;
