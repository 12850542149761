import { useEffect, useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import logoBlack from '../../assets/logoFilledBlack.png'; 
import logoWhite from '../../assets/logoFilledWhite.png'
import styles from './Header.module.css'; 
import { FaBars } from 'react-icons/fa';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import { useLanguage } from '../../context/LanguageContext.js';
import globalTranslations from '../../data/translations.js'

function Header({ isBackgroundAdaptive = false, withLogo = true }) {

    const { language } = useLanguage();
    const translations = globalTranslations.header

    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
    const menuRef = useRef(null);
    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setMobileMenuOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={`${styles.header} ${isBackgroundAdaptive ? styles.white : ''} ${isMobileMenuOpen ? styles.opened : ''}`}>
            <div>
                {withLogo ? (
                    <NavLink to="/work" className={styles.logo}>
                        <img src={logoBlack} alt="LOGO" />
                        {/* <p className={styles.logoTxt}>ST LOUIS</p> */}
                    </NavLink>
                ) : (
                    <NavLink to="/work" className={styles.logo}>
                        <img src={logoWhite} alt="LOGO" />
                        {/* <p className={styles.logoTxt}>ST LOUIS</p> */}
                    </NavLink>)}               
            </div>
            
            <ul className={styles.navbar}>
                <LanguageSwitcher isBackgroundAdaptive={isBackgroundAdaptive}></LanguageSwitcher>
                <li>
                    <NavLink to="/work" activeclassname={styles.active}>{translations[language].work}</NavLink>
                </li>
                <li>
                    <NavLink to="/showreel" activeclassname={styles.active}>{translations[language].showreel}</NavLink>
                </li>
                <li>
                    <NavLink to="/about" activeclassname={styles.active}>{translations[language].about}</NavLink>
                </li>
                <li>
                    <NavLink to="/contact" activeclassname={styles.active}>{translations[language].contact}</NavLink>
                </li>
            </ul>
            <div className={styles.smallSwitcher}><LanguageSwitcher isBackgroundAdaptive={isBackgroundAdaptive}></LanguageSwitcher></div>
            <div className={styles.smallNavbar}>
                <div className={`${styles.hamburger} ${isMobileMenuOpen ? styles.hide : ''}`} onClick={toggleMobileMenu}>
                    {isMobileMenuOpen ? "": <FaBars />}
                </div>
                <ul ref={menuRef} className={`${styles.smallDropdown} ${isMobileMenuOpen ? '' : styles.move}`}>
                    <li>
                        <NavLink to="/work" activeclassname={styles.active}><p>{translations[language].work}</p></NavLink>
                    </li>
                    <li>
                        <NavLink to="/showreel" activeclassname={styles.active}><p>{translations[language].showreel}</p></NavLink>
                    </li>
                    <li>
                        <NavLink to="/about" activeclassname={styles.active}><p>{translations[language].about}</p></NavLink>
                    </li>
                    <li>
                        <NavLink to="/contact" activeclassname={styles.active}><p>{translations[language].contact}</p></NavLink>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Header;