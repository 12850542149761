import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import SelectedVisuals from "../components/SelectedVisuals/SelectedVisuals.js";
import projectsData from '../data/projects.js';
import styles from './ProjectPage.module.css';
import SidePanel from "../components/SidePanel/SidePanel.js";
import Header from "../components/Header/Header.js";
import vector from '../assets/arrow.png';
import vectorBack from '../assets/arrow_back.png'
import back from '../assets/back_black.png'
import VimeoVideo from '../components/VimeoVideo/VimeoVideo.js';
import VimeoBackground from '../components/VimeoVideo/VimeoBackground.js';
import { motion, AnimatePresence } from 'framer-motion';
import { useLanguage } from '../context/LanguageContext.js';
import globalTranslations from '../data/translations.js';
import rolePriority from '../data/rolePriority.js'

const MOBILE_BREAKPOINT = 930;
const TABLET_BREAKPOINT = 1100;

const ProjectPage = () => {
    
    const navigate = useNavigate();
    const { language } = useLanguage();
    const { projectId } = useParams();
    const creditsTranslations = globalTranslations.credits
    const projectPageTranslations = globalTranslations.projectPage

    const projectData = useMemo(() => {
        const currentId = parseInt(projectId, 10);
        const numberOfProjects = projectsData.length;
        return {
            selectedProject: projectsData.find((project) => project.id === currentId),
            prevProjectId: currentId === 1 ? numberOfProjects : currentId - 1,
            nextProjectId: currentId === numberOfProjects ? 1 : currentId + 1,
        };
    }, [projectId]);

    const { selectedProject, prevProjectId, nextProjectId } = projectData;
    const [isCreditsVisible, setCreditsVisible] = useState(false);
    
    const resetDragState = useCallback(() => {
        dragRef.current = {
            startX: 0,
            startY: 0,
            isHorizontalDrag: false
        };

        setDragState({
            offset: 0,
            blurEffect: 0,
            opacityEffect: 1,
            direction: null,
            startX: 0,
            isDragging: false,
            isAnimated: false
        });
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        resetDragState()
    }, [projectId, resetDragState]);

    const [dragState, setDragState] = useState({
        offset: 0,
        blurEffect: 0,
        opacityEffect: 1,
        direction: null,
        startX: 0,
        isDragging: false,
        isAnimated: false 
    });

    const dragRef = useRef({
        startX: 0,
        startY: 0,
        isHorizontalDrag: false
    });

    const toggleCredits = () => {
        setCreditsVisible(!isCreditsVisible);
    };

    const [state, setState] = useState({
        isMobile: null,
        sidePanelWidth: '',
    });

    const DesktopVariants = {
        initial: { opacity: 0 },
        in: { opacity: 1 },
        out: { opacity: 0 },
    };

    const handleResize = () => {
        const width = window.innerWidth;
        setState(prev => ({
            ...prev,
            isMobile: width <= MOBILE_BREAKPOINT,
            sidePanelWidth: width < 530 ? '100vw' : 
                           width < TABLET_BREAKPOINT ? '50vw' : '35vw'
        }));
    };

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const mediaItems = useMemo(() => selectedProject?.visuals.map(visual => ({
        src: visual,
        type: /\.(mp4|webm|ogg)$/i.test(visual) ? 'video' : 
              /\.(jpg|jpeg|png|gif)$/i.test(visual) ? 'image' : null,
        autoplay: /\.(mp4|webm|ogg)$/i.test(visual),
    })) || [], [selectedProject?.visuals]);

    const renderNavigation = () => !state.isMobile && (
        <div className={styles.navigationWrapper}>
            <div 
                onClick={() => navigateToProject(prevProjectId)} 
                className={styles.previousProject}
            >
                <img className={styles.navigationImg} src={vectorBack} alt="Previous Project" />
            </div>
            <div 
                onClick={() => navigateToProject(nextProjectId)} 
            >
                <div className={styles.shadowWrapper}>
                    <img className={styles.navigationImg} src={vector} alt="Next Project" />
                </div>
            </div>
        </div>
    );

    const SWIPE_THRESHOLD = 100;  
    const MAX_BLUR = 2.5;
    const MAX_OPACITY = 1;
    const DRAG_SENSITIVITY = 10;  

    const navigateToProject = (projectId) => {
        navigate(`/project/${projectId}`)
    };
  
    const handleDragStart = useCallback((event) => {
        const isTouch = event.type.startsWith('touch');
        const startX = isTouch ? event.touches[0].clientX : event.clientX;
        const startY = isTouch ? event.touches[0].clientY : event.clientY;
    
        dragRef.current = {
            startX,
            startY,
            isHorizontalDrag: false,
        };
    
        setDragState((prev) => ({
            ...prev,
            startX,
            isDragging: false,
        }));
    }, []);

    const handleDragMove = useCallback((event) => {
        const currentX = event.type.startsWith('touch') 
            ? event.touches[0].clientX 
            : event.clientX;
        const currentY = event.type.startsWith('touch') 
            ? event.touches[0].clientY 
            : event.clientY;
    
        const deltaX = currentX - dragRef.current.startX;
        const deltaY = currentY - dragRef.current.startY;
    
        if (!dragRef.current.isHorizontalDrag) {
            if (Math.abs(deltaX) > DRAG_SENSITIVITY && 
                Math.abs(deltaX) > Math.abs(deltaY) * 2) {
                dragRef.current.isHorizontalDrag = true;
                if (event.cancelable) event.preventDefault();
            } else if (Math.abs(deltaY) > DRAG_SENSITIVITY) {
                return; 
            }
        }
    
        if (!dragRef.current.isHorizontalDrag) return;
    
        event.preventDefault();
    
        const newBlur = Math.min(Math.abs(deltaX) / 100, MAX_BLUR);
        const newOpacity = Math.max(1 - Math.abs(deltaX) / 2000, MAX_OPACITY);
    
        setDragState(prev => ({
            ...prev,
            offset: deltaX,
            blurEffect: newBlur,
            opacityEffect: newOpacity,
            direction: deltaX > 0 ? 'right' : 'left',
            isDragging: true
        }));
    }, []);

    const handleDragEnd = useCallback((event) => {
        if (!dragRef.current.isHorizontalDrag || !dragState.isDragging) {
            resetDragState();
            return;
        }
        const finalOffset = dragState.offset;
        if (Math.abs(finalOffset) > SWIPE_THRESHOLD) {
            const nextId = finalOffset > 0 ? prevProjectId : nextProjectId;
            
            setDragState(prev => ({
                ...prev,
                offset: finalOffset > 0 ? 500 : -500,
                isAnimated: true,
                opacity: 0
            }));
            setTimeout(() => {
                navigateToProject(nextId)
            }, 100);
        } else {
            setDragState(prev => ({
                ...prev,
                isAnimated: true,
                offset: 0,
                blurEffect: 0,
                opacityEffect: 1
            }));
            setTimeout(() => {
                resetDragState();
            }, 300);  
        }
    }, [dragState, prevProjectId, nextProjectId, navigate, navigateToProject, resetDragState]);

    const getDragStyle = useCallback(() => {
        const baseStyle = {
            transform: `translateX(${dragState.offset}px)`,
            transition: dragState.isAnimated 
                ? 'transform 0.3s ease-out, filter 0.3s ease-out, opacity 0.3s ease-out'
                : 'none',
            touchAction: 'none'
        };
        return baseStyle;
    }, [dragState.offset, dragState.isAnimated]);

    const roleToPriority = rolePriority.reduce((acc, role, index) => {
        acc[role] = index;
        return acc;
    }, {});

    const sortedCredits = selectedProject.credits.sort(([roleA], [roleB]) => {
        return (roleToPriority[roleA] || Infinity) - (roleToPriority[roleB] || Infinity);
    });

    if (!selectedProject) return <div>Project not found</div>;

    const isIPad = () => {
        return /iPad/i.test(navigator.userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
      };      

    return (
        <div className={styles.page}>
            <SidePanel 
                height="100vh" 
                width={state.sidePanelWidth} 
                position="left"
            >
                <div className={styles.sidePanelWrapper}>
                    <div className={styles.sidePanelTopWrapper}>
                        <h2 className={styles.panelTitle}>
                            <p>{selectedProject.customer}</p>
                            <p className={styles.projectName}>{selectedProject.name}</p>
                        </h2>
                        <div className={styles.panelUnderTitle}>
                            <p>{selectedProject.tags.join(' ')}</p>
                            <p>{selectedProject.year}</p>
                        </div>
                        <p className={styles.description}>{selectedProject.description[language]}</p>
                    </div>
                    <div className={styles.visibleMembers}>
                        <div>
                            {selectedProject.director ? (
                                <>
                                <span>{projectPageTranslations[language].director} : </span>
                                <p style={{ display: 'inline' }}>{selectedProject.director[0]}</p>
                                </>
                            ) : (
                                ""
                            )}
                        </div>
                        <div>
                            {selectedProject.production ? (
                                <>
                                    <span>PRODUCTION : </span>
                                    <p style={{ display: 'inline' }}>{selectedProject.production}</p>
                                </>
                            ) : ""}
                        </div>
                        <div>
                            {selectedProject.agency ? (
                                    <>
                                        <span>{projectPageTranslations[language].agency} : </span>
                                        <p style={{ display: 'inline' }}>{selectedProject.agency}</p>
                                    </>
                                ) : ""}
                        </div>
                    </div>
                    <div className={styles.creditsDropdown}>
                        <div onClick={toggleCredits} className={styles.toggleCreditsButton}>
                            {isCreditsVisible ? <p>CREDITS<img src={back} alt='Back' className={styles.opened}/></p> : <p>CREDITS<img src={back} alt='Back' className={styles.closed}/></p>}
                        </div>
                        <div className={`${styles.creditsWrapper} ${isCreditsVisible ? "" : styles.fadeOut}`}>
                        <div>
                            {sortedCredits.map(([role, name], index) => (
                                <p
                                    className={`${isCreditsVisible ? styles.fadeIn : ""}`}
                                    key={index}
                                    style={{ '--child-index': index + 1 }}
                                >
                                    <strong>{creditsTranslations[language][role]}</strong>: {name}
                                </p>
                            ))}
                        </div>
                        </div>
                    </div>
                </div>
            </SidePanel>

            {!state.isMobile && (
                <div>
                    <AnimatePresence mode="wait">
                        <motion.div
                            key={projectId}
                            initial="initial"
                            animate="in"
                            exit="out" 
                            variants={DesktopVariants}
                            transition={{ duration: 0.5 }}
                        >
                            <div className={`${styles.background}`}>
                            {isIPad() ? (
                                    
                                    <VimeoBackground videoId={selectedProject.vimeoId} />
                                ) : (
                                    <VimeoBackground videoId={selectedProject.vimeoId} />
                                )}
                                <VimeoBackground videoId={selectedProject.vimeoId} />
                            </div>
                        </motion.div>
                    </AnimatePresence>
                    <Header isBackgroundAdaptive withLogo={false}/>
                    {renderNavigation()}
                    {mediaItems.length > 0 && (
                        <div className={`${styles.secondPart} ${state.isMobile ? styles.secondPartMobile : ''}`}>
                            <SelectedVisuals mediaItems={mediaItems} projectID={selectedProject.id} />
                        </div>
                    )}
                </div>
            )}
            
            {state.isMobile && (   
                <div>
                    <Header/>
                    <motion.div 
                        initial={{ opacity: 0, y: 25 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ 
                            duration: 0.4,               
                            ease: "easeIn"               
                        }}
                        key={projectId} 
                    >
                        <div className={styles.mobileTopWrapper}>
                            <p onClick={() => navigateToProject(prevProjectId)}>PREV</p>
                            <p onClick={() => navigateToProject(nextProjectId)}>NEXT</p>
                        </div>
                        <div 
                            onTouchStart={handleDragStart}
                            onTouchMove={handleDragMove}
                            onTouchEnd={handleDragEnd}
                            onMouseDown={handleDragStart}
                            onMouseMove={handleDragMove}
                            onMouseUp={handleDragEnd}
                            className={styles.dragContainer}
                        >
                            <motion.div
                                style={{ 
                                    filter: `blur(${dragState.blurEffect}px)`,
                                    opacity: dragState.opacityEffect,
                                }}
                            >
                            <motion.div 
                                className={styles.mobileVideo}
                                style={getDragStyle()}
                            >
                                <div className={styles.mobileVideoWrapper}>
                                    <VimeoVideo 
                                        videoId={selectedProject.vimeoId} 
                                        loadingSpinnerColor="black"
                                        disableDrag={true}
                                        showUnmuteButton={true}
                                        autoplay
                                    />
                                </div>
                                <div className={styles.videoInfos}>
                                    <div>
                                        <p>{selectedProject.customer}</p>
                                        <p style={{ textAlign: 'end' }}>{selectedProject.name}</p>
                                    </div>
                                    <div>
                                        <p>{selectedProject.tags}</p>
                                        <p>{selectedProject.year}</p>
                                    </div>
                                </div>
                            </motion.div>      
                                       
                                {mediaItems.length > 0 && (
                                    <div className={`${styles.secondPart} ${styles.secondPartMobile}`}>
                                        <SelectedVisuals mediaItems={mediaItems} projectID={selectedProject.id} />
                                    </div>
                                )}
                            </motion.div>
                        </div>
                    </motion.div>
                </div>
            )}
        </div>
    );
};
export default React.memo(ProjectPage);