const mediaBaseUrl = process.env.REACT_APP_MEDIA_BASE_URL || '/medias';

/**
* Get media path by project name and file name.
* @param {string} path - Name of the project (directory name).
* @param {string} fileName - Name of the media file.
* @returns {string} - The URL to the requested media file.
*/
export const getMediaPath = (path, fileName) => {
    // Remove any leading/trailing slashes from path to prevent double slashes
    const cleanPath = path.replace(/^\/|\/$/g, '');
    return `${mediaBaseUrl}/${cleanPath}/${fileName}`;
};
